:root {
  --font-primary: Lato, Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-display: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-code: 'IBM Plex Mono', Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  --font-size-xs: 1.15rem;
  --font-size-sm: 1.3rem;
  --font-size-md: 1.5rem;
  --font-size-lg: 1.8rem;
  --font-size-xl: 2.4rem;
  --line-height: 1.6;
  /** light theme colors **/
  --color-back: #fff;
  --color-front: #444;
  --color-headings: #222;
  --color-link: blue;
  --color-link-visited: #0070f3;
  --color-subtle: #777;
  --color-subtle-background: #f9f9f9;
  --color-secondary: #555;
  --box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  --color-badge-background: #54a8fa;
  /** other colors **/
  --color-secondary-front: #555;
  --color-theme: #09a3d5;
  --color-theme-dark: #1095bf;
  --color-theme-contrast: #fff;
  --color-subtle-dark: #777;
  --color-subtle-gray: #888;
  --color-subtle-medium: #eee;
  --color-subtle-light: #f9f9f9;
  --color-background-code: rgba(122, 129, 129, 0.09);
  --color-button-primary: #29c15b;
  --color-button-primary-contrast: #fff;
  --color-button-secondary: #cdcdd4;
  --color-button-secondary-contrast: #686873;
  --color-button-danger: #d21313;
  --color-button-danger-contrast: #fff;
  --color-correct-light: #e3fde8;
  --color-correct-dark: #007737;
  --color-correct-middle: #03cd60;
  --color-incorrect-light: #fde7e7;
  --color-incorrect-dark: #d21313;
  --syntax-background: #f7f7f7;
  --syntax-text: #403f53;
  --syntax-selected-background: rgba(122, 129, 129, 0.17);
  --syntax-comment: #989fb1;
  --syntax-tag: #994cc3;
  --syntax-number: #aa0982;
  --syntax-selector: #994cc3;
  --syntax-operator: #994cc3;
  --syntax-function: #4876d6;
  --syntax-keyword: #994cc3;
  --syntax-regex: #5ca7e4;
  --ansi-green: #12dc55;
  --ansi-red: #f76464;
  --ansi-cyan: #00d8ff;
  --plyr-color-main: var(--color-theme);
  --plyr-font-family: var(--font-primary);
  --width-container: 800px;
  --size-profile-pic: 125px;
  --border-radius: 5px;
  --code-color: #e78c45;
  --color-button-background: #eee;
  --color-button-border: inherit;
}

/** dark theme colors **/
[data-theme='dark'] {
  --color-front: #eee;
  --color-back: #333;
  --color-headings: #29c15b;
  --color-link: #7acbe6;
  --color-link-visited: #7acbe6;
  --color-subtle: #cfcfcf;
  --color-subtle-medium: #777;
  --color-subtle-background: #333;
  --color-secondary: #ccc;
  --box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
  --color-badge-background: #666;
  --color-button-background: #151515;
  --color-button-border: #000;
}

[data-theme='dark'] h1,
h2,
h3 {
  color: var(--color-headings);
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: var(--font-display);
  line-height: var(--line-height);
  color: var(--color-front);
  background-color: var(--color-back);
  max-width: 980px;
  margin: 0 auto;
  padding: 0 1rem 1rem 1rem;
  word-wrap: break-word;
}

a {
  color: inherit;
  /* text-decoration: none; */
  /* background-color: transparent; */
}

* {
  box-sizing: border-box;
}

h1,
h2 {
  font-family: var(--font-primary);
}
h1,
h2,
h3 {
  color: var(--color-headings);
}

h1 {
  font-size: var(--font-size-xl);
  margin-bottom: 2rem;
}

h2 {
  font-size: var(--font-size-lg);
  margin-bottom: 1.5rem;
}

hr {
  border: 1px solid #ddd;
}

button {
  background-color: var(--color-button-background);
}

/* iframe {
  background: #ccc;
  border: 1px solid #ccc;
  height: 10rem;
  width: 100%;
  border-radius: 0.5rem;
  filter: invert(1);
} */

/* Algolia search */
.DocSearch-Dropdown,
.DocSearch-SearchBar {
  background-color: white;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

/* .page {
  width: var(--width-container);
  max-width: 100%;
  margin: 0 auto;
} */

.summary {
  border: 1px solid var(--color-subtle-medium);
  border-radius: var(--border-radius);
  margin: 0 auto 2rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
}

/* do not highlight lessons that are unavailable yet */
.summary:not([data-coming-soon='true']):hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.summary > div {
  padding: 2rem 2rem 1rem 2rem;
}

.summary .title {
  display: flex;
  justify-content: space-between;
}

.summary .title a {
  text-decoration: none;
}

.summary .title svg {
  height: 25px;
  width: 25px;
  /*
    make sure the SVG does not shrink
    due to other elements changing the layout
  */
  min-width: 25px;
  min-height: 25px;
  color: var(--color-subtle-medium);
}

.summary .title svg.enrolled {
  color: var(--color-theme-dark);
  fill: var(--color-theme-dark);
}

.summary .title svg.complete {
  color: var(--color-button-primary);
  fill: var(--color-button-primary);
}

.summary p {
  font-size: var(--font-size-sm);
  color: var(--color-subtle-dark);
  margin: 0;
}

.centerChildren {
  display: flex;
  justify-content: center;
}

.inline {
  font-family: monospace;
  color: #e78c45;
  font-size: 16px;
  border-radius: 0.3em;
  padding: 4px 5px 6px;
  white-space: nowrap;
}

code {
  color: var(--code-color);
  font-size: larger;
  margin: 0 0.3rem;
}

pre code {
  margin: 0;
}

blockquote {
  font-style: italic;
}

@media screen and (max-width: 720px) {
  body,
  html {
    width: 100%;
    /* height: 100%; */
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  [data-cy='course-page'],
  [data-cy='lesson'] {
    padding: 1rem;
  }

  .summary .title {
    text-align: center;
  }

  /* on small screens wrap the code blocks */
  code {
    white-space: break-spaces;
  }
}
